/* Copyright 2013-2023 Scout Exchange, LLC. All Rights Reserved. */

/* ==================================================

Base styles and component styles come from 'scout-ui/dist/scout-ui.css'
and are loaded into the page from 'src/App.tsx'

This stylesheet is for Charlie-specific styles

Base and component styles should be added via 'scout-ui'

================================================== */

/* ===
Table styles
---
These should be replaced by a table component version from scout-ui eventually
=== */

.table th,
.table td {
  padding-right: 5px;
  padding-left: 5px;
}

.table thead tr th {
  border-color: #255b80;
  border-width: 1px;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 5px;
}

.table tbody tr td {
  border-color: #b3c6d3;
  padding: 6px 5px;
}

.table thead tr th:first-child,
.table tbody tr td:first-child {
  padding-left: 0;
}

.table thead tr th:last-child,
.table tbody tr td:last-child {
  padding-right: 0;
}

/* end Table styles */
